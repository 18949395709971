<template>
  <v-app>
    <!-- header -->
    <!-- <v-toolbar color="grey darken-3" dark > -->
      <download-dialog/>
      <update-history-dialog
        :dialog="updateHistoryDialog"
        @close="updateHistoryDialog = false"
      />
      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        dark color="grey darken-3"
      >
      <v-toolbar-title style="width: 250px" class="ml-0 d-flex flex-column text-right">
        <span class="font-weight-bold">Trainer Admin Tool</span>
        <a class="caption text-decoration-underline white--text" @click="showUpdateHistory">v1.0.3</a>
      </v-toolbar-title>
        <v-tabs fixed-tabs v-model="activeTab">
            <v-tab v-for="tabItem in tabItems" :key="tabItem.id"
              @click="changeRoute(tabItem.name)"
              v-model="tabItem.id"
            >
              <v-icon small> {{ tabItem.icon }} </v-icon> <span>{{ tabItem.label }}</span>
              <v-badge v-if="tabItem.name === 'modellog' && downloadList.length > 0" color="teal" @click.native="toggleDownload">
                <span slot="badge"> {{ downloadList.length }} </span>
              </v-badge>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs"
                         v-on="on"
                         class="ml-3"
                         small
                         text
                         icon
                         @click.stop="openInNew(tabItem.name)">
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span class="text-caption">새창에서 열기</span>
              </v-tooltip>
            </v-tab>
        </v-tabs>
        <v-spacer></v-spacer>
        <div class="customer_text">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                class="username"
              >
                {{ customer }}
              </span>
            </template>
            <span>{{ customer }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                class="appid"
              >
                {{ appid }}
              </span>
            </template>
            <span>{{ appid }}</span>
          </v-tooltip>
        </div>
        <v-btn
            icon
            @click="logoutDialog = true"
          >
            <v-icon>exit_to_app</v-icon>
        </v-btn>
    </v-app-bar>
    <!-- body -->
    <br>
    <v-main>
      <v-container fluid>
        <v-layout align-center justify-center>
          <v-flex>
            <router-view></router-view>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <!-- footer -->
    <v-footer app color="grey darken-3" dark>
      <span>&copy; {{ new Date().getFullYear() }} kakao<b>enterprise</b></span>
    </v-footer>
    <!-- logout dialog -->
    <v-layout row justify-center>
      <v-dialog
        v-model="logoutDialog"
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline">
            <v-icon color="secondry" class="mr-2" medium>info</v-icon>
            <span>로그아웃</span>
          </v-card-title>
          <v-card-text class="text-xs-left subheading">로그아웃 하시겠습니까?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondry"
              @click="logout(false)"
            >
              취소
            </v-btn>
            <v-btn
              color="secondry"
              @click="logout(true)"
            >
              확인
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- logout dialog -->
  </v-app>
</template>

<script>
// import DropdownMenu from '@/components/common/DropdownMenu'
import { decodeToken, getCookie, deleteCookie } from '@/utils'
import Constant from '../constant'
import {mapMutations, mapState} from "vuex";
import DownloadDialog from "../components/common/DownloadDialog";
import UpdateHistoryDialog from "../components/common/UpdateHistoryDialog";
// import API from '@/api'

export default {
  name : "RootContainer",
  components: {
    UpdateHistoryDialog,
    DownloadDialog
    // DropdownMenu
  },
  created () {
    // console.log(Constant.USER_INFO)
    // this.customer = this.$store.customer

    this.$http.defaults.headers['Authorization'] = getCookie('x-access-token')
    this.$store.dispatch(Constant.USER_INFO, {
      customer: decodeToken(getCookie('x-access-token'))['customer'],
      appid: decodeToken(getCookie('x-access-token'))['appid']
    })
    // console.log(decodeToken(getCookie('x-access-token'))['customer'])

    this.customer = decodeToken(getCookie('x-access-token'))['customer']
    this.appid = decodeToken(getCookie('x-access-token'))['appid']

    this.tabdata.some(data => {
    if((data.id == 4)) return false;
      this.tabItems.push(data)
    });

    // this.$router.push({ name: '/TrainData' })
    // this.$router.push({ name : '학습데이터 관리'}).catch(err => {})
    // this.$router.push({ name : 'login'}).catch(err => {})

  Object.values(this.tabdata).forEach( ( obj , index) => {
    if(this.$route.name == obj.name){
      this.activeTab = index;
    }
  });

  },
  data () {
    return {
      tabItems : [],
      tabdata : [
        { id: 1, name:'train_data', label: '학습데이터 관리'},
        { id: 2, name:'modellog', label: '학습결과 관리'},
        { id: 3, name:'botlog', label: '학습BOT 관리'},
        { id: 4, name:'user', label: '사용자 관리'},
      ],
      activeTab : 1,
      logoutDialog: false,
      customer: "",
      updateHistoryDialog: false,
    }
  },
  computed: {
    ...mapState({
      downloadList: 'downloadList',
    }),
  },
  methods: {
    ...mapMutations({
      toggleDownload: 'toggleDownload',
    }),
    initBotItem(botItem) {
      console.log('>> init botItem')
      localStorage.removeItem('botid')
      localStorage.removeItem('botname')
      localStorage.removeItem('fullname')
    },
    showUpdateHistory() {
      this.updateHistoryDialog = true
    },
    changeRoute(routeName){
      console.log(">>>> CHANGE ROUTE ::", routeName)
      clearInterval(this.$store.state.timerId)
      this.$router.push({ name : routeName}).catch(err => {})
    },
    openInNew(routeName) {
      console.log(">>>> OPEN IN NEW ::", routeName)
      clearInterval(this.$store.state.timerId)
      let routeData = this.$router.resolve({name: routeName})
      window.open(routeData.href, '_blank')
    },
    logout (value) {
      if (value) {
        // this.$http.post(API.LOGOUT, {
        //   'access_token': getCookie('x-access-token'),
        // }).then(res => {
        deleteCookie('x-access-token')
        this.initBotItem()
        this.$store.dispatch(Constant.USER_INFO, {})
        this.$router.push({ name: 'login'})
        // })
        // deleteCookie('x-access-token')
        // this.$store.dispatch(Constant.USER_INFO, {})
        // this.$router.push({ name: 'login'})
      }
      this.logoutDialog = false
    }
  },
}
</script>
<style>
  .v-btn {
    text-transform:none !important;
  }
</style>
