<template>
  <v-dialog
      persistent
      v-model="showDownload"
      width="800"
  >
    <v-card>
      <v-card-title class="headline">
        <v-icon style="margin-right:10px;" large color="teal">cloud_download</v-icon>
        <span>학습, 평가 다운로드 상태</span>
      </v-card-title>

      <v-card-text class="text-xs-left subheading">
        <v-container>
          <v-flex>
            <v-data-table
              :headers="headers"
              :items="downloadList"
              :disable-pagination="true"
              item-key="requestid"
              class="elevation-1"
              :sort-by="['status']"
              hide-default-footer
            >
              <template v-slot:item.status="{ item }">
                <v-progress-linear v-if="item.status === 'start'" rounded indeterminate color="teal"></v-progress-linear>
                <v-icon v-if="item.status === 'done'" color="teal">mdi-checkbox-marked-circle</v-icon>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-btn v-if="item.status === 'done'" icon @click="delDownload(item.id)">
                  <v-icon small>delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-flex>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            @click="toggleDownload"
        >
          확인
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapMutations, mapState} from "vuex";

export default {
  components: {

  },
  data() {
    return {
      headers: [
        { text: '봇', value: 'botId' },
        { text: '학습 버전', value: 'version' },
        { text: '타입', value: 'type' },
        { text: '상태', value: 'status' },
        { text: '', value: 'delete' },
      ]
    }
  },
  create() {
  },
  mounted() {
  },
  computed: {
    ...mapState({
      showDownload: 'downloadDialog',
      downloadList: 'downloadList',
    }),
  },
  methods: {
    ...mapMutations({
      toggleDownload: 'toggleDownload',
      delDownload: 'delDownload',
    }),
  },
}
</script>
<style>
</style>
