<template>
  <v-dialog persistent v-model="dialog" width="600">
    <v-card>
      <v-card-title class="headline">
        <v-icon style="margin-right:10px;" medium>mdi-information-outline</v-icon><span>업데이트 기록</span>
      </v-card-title>

      <v-card-text class="mt-2 text-xs-left subheading">
        <v-expansion-panels inset multiple>
          <v-expansion-panel v-for="(content,i) in contents" :key="i">
            <v-expansion-panel-header class="py-0">
              <span class="font-weight-bold">{{ content.version }} 업데이트</span>
              <span class="caption text-right">{{ content.date }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content style="background:#F5F5F5" class="pt-3">
              <v-container v-if="content.items.newItem && content.items.newItem.length > 0">
                <span class="font-weight-bold">신규 기능</span>
                <ul>
                  <li v-for="(nStr,ndx) in content.items.newItem" :key="ndx" class="pl-3">
                    {{ nStr }}
                  </li>
                </ul>
              </v-container>
              <v-container v-if="content.items.modifyItem && content.items.modifyItem.length > 0">
                <span class="font-weight-bold">기능 개선</span>
                <ul>
                  <li v-for="(mStr,mdx) in content.items.modifyItem" :key="mdx" class="pl-3">
                    {{ mStr }}
                  </li>
                </ul>
              </v-container>
              <v-container v-if="content.items.bugItem && content.items.bugItem.length > 0">
                <span class="font-weight-bold">버그 수정</span>
                <ul>
                  <li v-for="(bStr,bdx) in content.items.bugItem" :key="bdx" class="pl-3">
                    {{ bStr }}
                  </li>
                </ul>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" @click="close">닫기</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  props: {
    dialog: Boolean
  },
  data() {
    return {
      contents: [
        {
          version: '1.0.3',
          date: '2022/01/10',
          items: {
            newItem: [
              '학습데이터 관리 intent 필터 기능 추가',
              '학습데이터 관리 intent 정렬 기능 추가 (이름, 날짜)',
              '엑셀 업로드시 기존 데이터 삭제여부 및 중복 체크 기능 추가',
              '학습결과 발화를 학습셋으로 등록기능 추가'
            ],
            modifyItem: [
              '인텐트 등록/수정 입력 프로세스 개선'
            ],
            bugItem: [
              '로그인 팅김 현상 조치',
              '학습발화와 평가 결과를 보기 위해 화면을 전환하면 초기화되는 오류 조치',
              '정확도 표기 오류 조치'
            ],
          }
        },
        {
          version: '1.0.2',
          date: '2021/12/22',
          items: {
            newItem: [
              '학습데이터, 평가결과 확인시 전체 인텐트 [ALL] 추가',
              '평가결과 조회조건에 1위 Intent 추가',
            ],
            modifyItem: [
              '인텐트 이름 잘리는 현상 개선',
            ],
            bugItem: [
              '법인 변경시 기존법인 데이터 조회되는 현상',
            ],
          }
        },
        {
          version: '1.0.1',
          date: '2021/12/15',
          items: {
            newItem: [
            ],
            modifyItem: [
              '탭/메뉴 이동시 선택된 봇 해제되는 현상 개선',
              '법인 ID가 긴 경우, 사용자 ID가 잘려보이는 현상 개선',
              '데이터 조회 속도 개선',
            ],
            bugItem: [
            ],
          }
        }
      ],
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
