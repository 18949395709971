var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('download-dialog'),_c('update-history-dialog',{attrs:{"dialog":_vm.updateHistoryDialog},on:{"close":function($event){_vm.updateHistoryDialog = false}}}),_c('v-app-bar',{attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","dark":"","color":"grey darken-3"}},[_c('v-toolbar-title',{staticClass:"ml-0 d-flex flex-column text-right",staticStyle:{"width":"250px"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Trainer Admin Tool")]),_c('a',{staticClass:"caption text-decoration-underline white--text",on:{"click":_vm.showUpdateHistory}},[_vm._v("v1.0.3")])]),_c('v-tabs',{attrs:{"fixed-tabs":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabItems),function(tabItem){return _c('v-tab',{key:tabItem.id,on:{"click":function($event){return _vm.changeRoute(tabItem.name)}},model:{value:(tabItem.id),callback:function ($$v) {_vm.$set(tabItem, "id", $$v)},expression:"tabItem.id"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(tabItem.icon)+" ")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(tabItem.label))]),(tabItem.name === 'modellog' && _vm.downloadList.length > 0)?_c('v-badge',{attrs:{"color":"teal"},nativeOn:{"click":function($event){return _vm.toggleDownload($event)}}},[_c('span',{attrs:{"slot":"badge"},slot:"badge"},[_vm._v(" "+_vm._s(_vm.downloadList.length)+" ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":"","text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openInNew(tabItem.name)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',{staticClass:"text-caption"},[_vm._v("새창에서 열기")])])],1)}),1),_c('v-spacer'),_c('div',{staticClass:"customer_text"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"username"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.customer)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.customer))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"appid"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.appid)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.appid))])])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.logoutDialog = true}}},[_c('v-icon',[_vm._v("exit_to_app")])],1)],1),_c('br'),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',[_c('router-view')],1)],1)],1)],1),_c('v-footer',{attrs:{"app":"","color":"grey darken-3","dark":""}},[_c('span',[_vm._v("© "+_vm._s(new Date().getFullYear())+" kakao"),_c('b',[_vm._v("enterprise")])])]),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.logoutDialog),callback:function ($$v) {_vm.logoutDialog=$$v},expression:"logoutDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"secondry","medium":""}},[_vm._v("info")]),_c('span',[_vm._v("로그아웃")])],1),_c('v-card-text',{staticClass:"text-xs-left subheading"},[_vm._v("로그아웃 하시겠습니까?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondry"},on:{"click":function($event){return _vm.logout(false)}}},[_vm._v(" 취소 ")]),_c('v-btn',{attrs:{"color":"secondry"},on:{"click":function($event){return _vm.logout(true)}}},[_vm._v(" 확인 ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }